import React, { useCallback, useState, useEffect } from "react";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import {
  Divider,
  IconButton,
  Drawer,
  Fade,
  useScrollTrigger,
} from "@material-ui/core";
import styled from "@emotion/styled";
import { MenuRounded, MenuOpenRounded } from "@material-ui/icons";
import { Logo } from "./Logo";
import SelectLanguage from "./Selection";
import { AppBar, MobileSection, RightMenu, LeftMenu } from "./style";

const CustomLink = styled(Link)`
  padding: 0.3em 0.3em;
  :hover {
    background-color: rgba(64, 87, 109, 0.07);
    border-radius: 0.2em;
  }
`;

const Header = () => {
  const { languages, originalPath, language, changeLanguage } = useI18next();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const trigger = useScrollTrigger();

  const handleWindowResize = useCallback(() => {
    setOpen(false);
  }, []);
  useEffect(() => {
    if (trigger) {
      setOpen(false);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [handleWindowResize, trigger]);

  const handleLangChange = useCallback(
    (event) => {
      changeLanguage(event.value, originalPath);
    },
    [changeLanguage, originalPath]
  );

  const MenuMobile = (
    <div
      style={{
        width: 200,
        height: "100%",
      }}
    >
      <div
        style={{
          padding: "0em 1em",
        }}
      >
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={useCallback((e) => {
            e.preventDefault();
            setOpen(false);
          }, [])}
        >
          <MenuOpenRounded size="small" />
        </IconButton>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "1em 0em",
            margin: "0.5em 0em",
            gap: "1em",
          }}
        >
          <Link
            to="/"
            activeClassName="active"
            partiallyActive={false}
            activeStyle={{
              fontWeight: 700,
            }}
          >
            {t("Home")}
          </Link>

          <Link
            to="/how-to-use"
            activeClassName="active"
            partiallyActive={true}
            activeStyle={{
              fontWeight: 700,
            }}
          >
            {t("How to use")}
          </Link>

          <Link
            to="/blog/"
            activeClassName="active"
            partiallyActive={true}
            activeStyle={{
              fontWeight: 700,
            }}
          >
            {t("Blog")}
          </Link>

          <Divider style={{ margin: "3px 0px", width: "100%" }} />

          <SelectLanguage
            languages={languages}
            currency={language}
            handleLangChange={handleLangChange}
          />
        </div>
      </div>
    </div>
  );

  return (
    <AppBar>
      <Link
        to="/"
        aria-label="home"
        style={{
          display: "flex",
          alignSelf: "center",
          padding: "0.5em 0em",
        }}
      >
        <Logo style={{ maxWidth: 130 }} />
      </Link>
      <LeftMenu>
        <CustomLink
          activeClassName="active"
          partiallyActive={false}
          to="/"
          style={{
            padding: "0.4em 0.6em",
          }}
          activeStyle={{
            backgroundColor: "rgba(64, 87, 109, 0.07)",
            fontWeight: 700,
          }}
        >
          {t("Home")}
        </CustomLink>
        <CustomLink
          activeClassName="active"
          partiallyActive={true}
          activeStyle={{
            backgroundColor: "rgba(64, 87, 109, 0.07)",
            fontWeight: 700,
          }}
          to="/how-to-use/"
          style={{
            padding: "0.4em 0.6em",
          }}
        >
          {t("How to use")}
        </CustomLink>

        <CustomLink
          activeClassName="active"
          partiallyActive={true}
          activeStyle={{
            backgroundColor: "rgba(64, 87, 109, 0.07)",
            fontWeight: 700,
          }}
          to="/blog/"
          style={{
            padding: "0.4em 0.6em",
          }}
        >
          {t("Blog")}
        </CustomLink>
      </LeftMenu>
      <RightMenu>
        <SelectLanguage
          style={{ marginLeft: "1em" }}
          languages={languages}
          currency={language}
          handleLangChange={handleLangChange}
        />
      </RightMenu>
      <MobileSection>
        <Fade
          in={!open}
          style={{ transformOrigin: "0 0 0" }}
          {...(!open ? { timeout: 1500 } : {})}
        >
          <IconButton
            onClick={useCallback((e) => {
              e.preventDefault();
              setOpen(true);
            }, [])}
          >
            <MenuRounded size="small" />
          </IconButton>
        </Fade>
        <Drawer variant="persistent" anchor={"right"} open={open}>
          {MenuMobile}
        </Drawer>
      </MobileSection>
    </AppBar>
  );
};

export default Header;
