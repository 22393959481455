import React, { useState } from "react";
import styled from "@emotion/styled";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  withStyles,
  Divider,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const WrapperText = styled.div`
  font-size: clamp(0.9rem, 2.5vw, 0.9rem);
  padding: 0em 1em;
  margin-bottom: 1em;
  & p {
    text-indent: 20px;
    font-weight: 300;
    font-family: "Nunito Sans", "prompt";
  }
  & ul {
    font-weight: 300;
  }
  & li.checked {
    font-weight: 300;
    font-family: "Nunito Sans", "prompt";
    padding-left: 0.4em;
  }
  & li.checked::marker {
    content: "✅ ";
  }
`;

const ListText = withStyles({
  primary: {
    color: "#414141",
    fontWeight: 800,
    fontSize: "clamp(0.9rem, 2.5vw, 1rem)",
    fontFamily: "'Nunito Sans', 'prompt'",
  },
})(ListItemText);

const ListCustom = withStyles({
  root: {
    cursor: "pointer",
  },
})(List);

const ComponentItem = ({ text, title }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem onClick={handleClick}>
        <ListText primary={title} style={{ color: "#000", fontWeight: 700 }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {text}
        </List>
      </Collapse>
      <Divider component="li" />
    </>
  );
};

const ListFaqComponent = () => {
  const { t } = useTranslation();
  const ListFAQ = () => [
    {
      title: <>{t("Faq.What Creator")} </>,
      text: (
        <WrapperText>
          <p>{t("Faq.KUU Creator is an application")}</p>
          <ul>
            <li className="checked">{t("Faq.AI background removal")}</li>
            <li className="checked">{t("Faq.Animations")}</li>
            <li className="checked">{t("Faq.10,000+ free cutouts")}</li>
            <li className="checked">
              {t("Faq.Royalty-free model cutouts with face change AI")}
            </li>
            <li className="checked">{t("Faq.Designed texts")}</li>
            <li className="checked">
              {t("Faq.Millions of free pics & backgrounds")}
            </li>
          </ul>
        </WrapperText>
      ),
    },
    {
      title: <>{t("Faq.Can I try KUU Creator app for free?")}</>,
      text: (
        <WrapperText>
          <p>{t("Faq.Absolutely")}</p>
          {/* <p>{t("Faq.*Only Pro")}</p> */}
        </WrapperText>
      ),
    },
    {
      title: (
        <>{t("Faq.What are the perks of KUU Creator Pro subscription?")}</>
      ),
      text: (
        <WrapperText>
          <p>{t("Faq.Unlock benefits")}</p>
          {/* <p>{t("Faq.Promote your business")}</p>
          <p> {t("Faq.*You can remove")}</p> */}
          <ul>
            <li className="checked">{t("Faq.Remove watermark")}</li>
            <li className="checked">
              {t("Faq.Remove background up to 100 photos/day")}
            </li>
            <li className="checked">
              {t("Faq.Unlimited access to all assets & features")}
            </li>
            <li className="checked">
              {t("Faq.Share your creation in video format")}
            </li>
          </ul>
          <p> {t("Faq.*You can remove")}</p>
        </WrapperText>
      ),
    },
    {
      title: <>{t("Faq.What is AI Designer?")}</>,
      text: (
        <WrapperText>
          <p>{t("Faq.Get an appealing product image instantly")}</p>
        </WrapperText>
      ),
    },
    {
      title: <>{t("Faq.Will I still have my")}</>,
      text: (
        <WrapperText>
          <p>{t("Faq.Your data")}</p>
        </WrapperText>
      ),
    },
    {
      title: <>{t("Faq.How can I restore my purchase?")}</>,
      text: (
        <WrapperText>
          <p>
            <Trans components={[<strong />]}>{t("Faq.Tab")}</Trans>
          </p>
        </WrapperText>
      ),
    },
    {
      title: <>{t("Faq.What is a cutout stock?")}</>,
      text: (
        <WrapperText>
          <p>{t("Faq.Cutout stocks")}</p>
          <ul>
            <li>
              <Trans components={[<strong />]}>
                {t("Faq.People cutout stocks")}
              </Trans>
            </li>
            <li>
              <Trans components={[<strong />]}>
                {t("Faq.Object cutout stocks")}
              </Trans>
            </li>
          </ul>
          <p>{t("Faq.You can use our")}</p>
        </WrapperText>
      ),
    },
    {
      title: <>{t("Faq.Are the assets in KUU")}</>,
      text: (
        <WrapperText>
          <p>{t("Faq.All the assets")}</p>
          <p>{t("Faq.*People cutout stocks")}</p>
        </WrapperText>
      ),
    },
    {
      title: <>{t("Faq.Can I use KUU Creator for social media?")}</>,
      text: (
        <WrapperText>
          <p>{t("Faq.Of course.")}</p>
        </WrapperText>
      ),
    },
    {
      title: <>{t("Faq.Where do I go for help?")}</>,
      text: (
        <WrapperText>
          <p>
            <Trans components={[<strong />]}>{t("Faq.Click")}</Trans>
          </p>
        </WrapperText>
      ),
    },
    {
      title: <>{t("Faq.How can I cancel my subscription?")}</>,
      text: (
        <WrapperText>
          <p>{t("Faq.KUU Creator")}</p>
          <p>{t("Faq.For iOS:")}</p>
          <ul>
            <li>
              <Trans components={[<strong />]}>{t("Faq.Open the")}</Trans>
            </li>
            <li>
              <Trans components={[<strong />]}>{t("Faq.Tap your name")}</Trans>
            </li>
            <li>
              <Trans components={[<strong />]}>{t("Faq.Select")}</Trans>
            </li>
            <li>
              <Trans components={[<strong />]}>{t("Faq.Tap Cancel")}</Trans>
            </li>
          </ul>
          <p>{t("Faq.For Android:")}</p>
          <ul>
            <li>
              <Trans components={[<strong />]}>{t("Faq.Open")}</Trans>
            </li>
            <li>{t("Faq.Tap your profile")}</li>
            <li>
              <Trans components={[<strong />]}>
                {t("Faq.Select Subscriptions")}
              </Trans>
            </li>
            <li>
              <Trans components={[<strong />]}>
                {t("Faq.Tap KUU Creator")}
              </Trans>
            </li>
            <li>
              <Trans components={[<strong />]}>
                {t("Faq.Tap Cancel subscription")}
              </Trans>
            </li>
          </ul>
          <p>{t("Faq.*Please keep in mind")}</p>
        </WrapperText>
      ),
    },
    {
      title: (
        <>
          {t("Faq.What should I do if I have problems with in-app purchases?")}
        </>
      ),
      text: (
        <WrapperText>
          <p>
            <Trans components={[<strong />]}>
              {t("Faq.Please try restoring")}
            </Trans>
          </p>
        </WrapperText>
      ),
    },
    {
      title: <>{t("Faq.Is there anything")}</>,
      text: (
        <WrapperText>
          <p>{t("Faq.Here are the tips")}</p>
          <ul>
            <li>
              <Trans components={[<strong />]}>{t("Faq.Always make")}</Trans>
            </li>
            <li>
              <Trans components={[<strong />]}>
                {t("Faq.You should have")}
              </Trans>
            </li>
            <li>
              <Trans components={[<strong />]}>{t("Faq.Choose a photo")}</Trans>
            </li>
            <li>
              <Trans components={[<strong />]}>
                {t("Faq.KUU Creator automatically")}
              </Trans>
            </li>
            <li>
              <Trans components={[<strong />]}>{t("Faq.When you see")}</Trans>
            </li>
            <li>
              <Trans components={[<strong />]}>{t("Faq.It can take")}</Trans>
            </li>
          </ul>
        </WrapperText>
      ),
    },
  ];

  return (
    <ListCustom
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {ListFAQ().map((item, index) => (
        <ComponentItem key={index} title={item.title} text={item.text} />
      ))}
    </ListCustom>
  );
};

export default ListFaqComponent;
