import React from "react";
import Select, { components } from "react-select";
import LanguageOutlined from "@material-ui/icons/LanguageOutlined";

const customStyles = {
  control: (styles) => ({
    ...styles,
    "&:hover": { borderColor: "none" },
    border: "0px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "none",
    borderRadius: 10,
    cursor: "pointer",
    width: 130,
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    color: "#323232",
    backgroundColor: isFocused ? "#F2F3F4" : null,
    textTransform: "capitalize",
  }),
  singleValue: () => ({
    fontSize: "0.9rem",
    textTransform: "capitalize",
    maxWidth: 90,
  }),
  menuList: (styles) => ({
    ...styles,
    fontSize: "clamp(0.9rem, 2.5vw, 0.9rem)",
  }),
};

const SelectLanguage = ({ languages, handleLangChange, currency, style }) => {
  const options = languages.map((object) => {
    return {
      value: object,
      label: object === "th" ? "ภาษาไทย" : "English (US)",
    };
  });

  return (
    <div style={{ ...style }}>
      <Select
        styles={customStyles}
        closeMenuOnSelect
        name="language"
        aria-label={"Not searchable"}
        searchable={true}
        isSearchable={false}
        isClearable={false}
        menuPlacement="auto"
        components={{
          Control: ({ children, ...rest }) => (
            <components.Control {...rest}>
              <LanguageOutlined
                fontSize="small"
                style={{ maxWidth: 25, width: "100%" }}
              />{" "}
              {children}
            </components.Control>
          ),

          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
        defaultValue={currency === "th" ? options[1] : options[0]}
        options={options}
        onChange={handleLangChange}
      />
    </div>
  );
};
export default SelectLanguage;
