import styled from "@emotion/styled";
import { Link } from "gatsby-plugin-react-i18next";

export const AppBar = styled.header`
  max-height: 80px;
  padding: 0.2em 2em;
  background-color: #fff;
  box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
  & a {
    text-decoration: none;
    color: #000;
  }
`;

export const Wrap = styled.li`
  list-style: none;
  display: inline;
  position: relative;
  padding: 0em 0.5em;
  text-align: center;
  a {
    color: #323232;
    text-decoration: none;
    font-weight: 300;
    position: relative;
    :active {
      font-weight: 700;
    }
    ::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      top: -22px;
      width: 100%;
      height: 7px;
      border-radius: 0px 0px 5px 5px;
      background: ${(props) =>
        props.background ? props.background : "#fbb400"};
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 250ms ease-in;
    }
    :hover::after {
      transform: scaleX(1);
      transform-origin: center;
    }
  }

  &.is-active {
    & a {
      font-weight: 700;
    }
  }
`;

export const MobileButton = styled.a`
  display: flex;
  justify-content: flex-start;
  color: #323232;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  margin: 0.2em 0em;
  text-decoration: none;
  :hover {
    color: #fbb400;
  }
`;

export const MobileSection = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  @media (min-width: 1155px) {
    display: none;
  }
`;

export const RightMenu = styled.div`
  display: flex;
  @media (max-width: 1155px) {
    display: none;
  }
`;

export const LeftMenu = styled.div`
  display: flex;
  flex: 1;
  margin-left: 1em;
  gap: 0.2em;
  @media (max-width: 1155px) {
    display: none;
  }
`;

// ------------------------ Footer -------------------------
export const FooterContainer = styled.footer`
  padding: 1em;
  background-color: #474747;
`;

export const FooterLinkMenu = styled(Link)`
  color: #fff;
  font-weight: 100;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  text-decoration: none;
  margin-bottom: 0.35em;
`;

export const Button = styled.a`
  color: #fff;
  font-weight: 100;
  text-decoration: none;
  margin-bottom: 0.35em;
`;

export const WrapperCopyRight = styled.div`
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 1.5em;
  color: #fff;
  & p {
    font-size: clamp(0.8rem, 2.5vw, 0.8rem);
  }
  .address {
    color: #fff;
    font-weight: 100;
  }
`;

export const WrapperContent = styled.div`
  padding-top: 2em;
  display: flex;
  flex: 1;
  flex-flow: row;
  justify-content: space-between;
  @media (max-width: 960px) {
    flex-flow: column;
  }
`;
export const WrapperMenu = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: baseline;
  padding: 20px 0px;
  justify-content: center;
  @media (max-width: 600px) {
    justify-content: flex-start;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-flow: column;
  padding: 1em 1em;
  flex: 25%;
  max-width: 25%;
  @media (max-width: 960px) {
    flex: 33%;
    max-width: 33%;
  }
  @media (max-width: 600px) {
    flex: 50%;
    max-width: 50%;
  }
`;
