const PIXMAKER_SERVER =
  process.env.GATSBY_PIXMAKER_SERVER || "http://localhost:5000";
const PIXMAKER_APP = process.env.GATSBY_PIXMAKER_APP || "http://localhost:3000";
const CONTACT_US_URL =
  process.env.GATSBY_CONTACT_US_URL || "https://formspree.io/f/xyyblkyr";

const LINK_VIDEO =
  "https://www.youtube.com/embed/sWoTgCLDEkM?controls=1&loop=1&rel=0&autoplay=0&mute=1&playlist=sWoTgCLDEkM";

const DISABLE_PRO = process.env.GATSBY_APP_DISABLE_PRO;

const GOOGLE_CLIENT_ID = process.env.GATSBY_GOOGLE_CLIENT_ID;
const FB_CLIENT_ID = process.env.GATSBY_FB_CLIENT_ID;

const APPLE_STORE = process.env.GATSBY_APPLE_STORE;
const GOOGLE_STORE = process.env.GATSBY_GOOGLE_STORE;

export {
  PIXMAKER_SERVER,
  PIXMAKER_APP,
  CONTACT_US_URL,
  LINK_VIDEO,
  DISABLE_PRO,
  GOOGLE_CLIENT_ID,
  FB_CLIENT_ID,
  APPLE_STORE,
  GOOGLE_STORE,
};
