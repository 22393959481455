import React from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { CONTACT_US_URL } from "../../utils/config";

const WrapperFieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: 0px;
  max-width: 600px;
  margin-right: auto;
  padding: 1em;
`;

const Label = styled.label`
  display: flex;
  flex-flow: column;

  padding: 1rem;
`;

const Input = styled.input`
  margin: 0.5em 0em;
  padding: 1em;
`;
const Textarea = styled.textarea`
  margin: 0.5em 0em;
`;
const Button = styled.input`
  padding: 1em 3em;
  align-self: flex-start;
  margin-left: 3em;
  cursor: pointer;
  background-image: linear-gradient(-32deg, #fdbc2b 0%, #f69724 100%);
  border: 0px;
  border-radius: 4px;
  color: #fff;
`;

const ContentMsg = styled.div`
  padding: 1em;
  text-align: center;
  color: #000;
`;

const ContactForm = () => {
  const { t } = useTranslation();
  const [input, setInputs] = React.useState({
    firstLastName: "",
    email: "",
    message: "",
  });
  const [serverState, setServerState] = React.useState({
    submitting: false,
    status: null,
  });

  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      setInputs({
        firstLastName: "",
        email: "",
        message: "",
      });
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setServerState({ submitting: true });
    axios({
      method: "POST",
      url: CONTACT_US_URL,
      data: input,
    })
      .then(() => {
        handleServerResponse(true, "Thanks for contacting us.");
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error);
      });
  };
  return (
    <>
      <form onSubmit={handleOnSubmit}>
        <WrapperFieldSet id="fs-frm-inputs">
          <Label>
            {t("Contact.Full Name")}
            <Input
              required
              type="text"
              name="firstLastName"
              id="firstLastName"
              placeholder="John Doe"
              onChange={handleOnChange}
              value={input.firstLastName}
            />
          </Label>
          <Label>
            {t("Contact.Email Address")}

            <Input
              required
              type="email"
              name="email"
              id="email"
              placeholder="example@example.com"
              onChange={handleOnChange}
              value={input.email}
            />
          </Label>

          <Label>
            {t("Contact.Message")}
            <Textarea
              required
              name="message"
              id="message"
              onChange={handleOnChange}
              value={input.message}
              rows="8"
            />
          </Label>

          {serverState.status && (
            <ContentMsg>{serverState.status.msg}</ContentMsg>
          )}
        </WrapperFieldSet>

        <div>
          <Button
            type="submit"
            disabled={serverState.submitting}
            value={t("Contact.Submit")}
          />
        </div>
      </form>
    </>
  );
};

export default ContactForm;
