import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { RichText, Date } from "prismic-reactjs";
import styled from "@emotion/styled";
import Divider from "@material-ui/core/Divider";

const StyledLink = styled(Link)`
  font-size: clamp(1.1rem, 2.5vw, 1.2rem);
  color: #000;
  font-style: normal;
  text-decoration: none;
  :hover {
    color: #fbb400;
    text-decoration: underline;
  }
`;

const AppTutorial = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const { tutorials } = useStaticQuery(graphql`
    query {
      tutorials: allPrismicPostApp(
        sort: { fields: [last_publication_date], order: DESC }
        filter: {
          data: {
            categories: {
              elemMatch: { category: { slug: { eq: "tutorials" } } }
            }
          }
        }
      ) {
        nodes {
          id
          uid
          lang
          data {
            title {
              raw
            }
            description {
              raw
            }
            date
          }
        }
      }
    }
  `);

  return (
    <div>
      {tutorials.nodes.length !== 0 &&
        tutorials.nodes
          .filter((e) => e.lang === currentLang)
          .map((object) => (
            <div key={object.id}>
              <div
                style={{
                  padding: "0.5em 1em",
                }}
              >
                <StyledLink to={`/mobile/${object.uid}`}>
                  {RichText.asText(object.data.title.raw).length !== 0 &&
                    RichText.asText(object.data.title.raw)}
                </StyledLink>
                <div
                  style={{
                    fontSize: "clamp(0.9rem, 2.5vw, 0.9rem)",
                    color: "#000",
                    fontWeight: 300,
                  }}
                >
                  {RichText.asText(object.data.description.raw).length !== 0 &&
                    RichText.asText(object.data.description.raw)}
                </div>
                <div
                  style={{
                    color: "rgba(147, 147, 147, 1)",
                    padding: "0.3em 0em",
                    fontSize: "0.75rem",
                  }}
                >
                  <time>
                    {new Intl.DateTimeFormat("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    }).format(Date(object.data.date))}
                  </time>
                </div>
                <Divider
                  style={{
                    margin: "0.5em 1em",
                  }}
                />
              </div>
            </div>
          ))}
    </div>
  );
};

export default AppTutorial;
