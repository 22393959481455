import React from "react";
import { Global } from "@emotion/react";
import Header from "./Header";
import WebCookie from "./WebCookie";
import FooterMobile from "../Mobile/Footer/index";
import { GlobalStyle } from "./GlobalStyle";

const Layout = ({ children }) => {
  return (
    <>
      <Global styles={GlobalStyle} />
      <div
        style={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            backgroundColor: "#fff",
            zIndex: 4,
          }}
        >
          <Header />
          <section
            style={{
              overflowX: "hidden",
            }}
          >
            {children}
          </section>
        </div>

        <FooterMobile />
        <WebCookie />
      </div>
    </>
  );
};

export default Layout;
