import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Dialog, IconButton, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ContactForm from "../../Contact/index";
import AppTutorial from "./Tutorial";
import AppUserGuide from "./UserGuide";
import ListFaqComponent from "./ListFaq";

const CustomLink = styled(Link)`
  all: unset;
  font-weight: 300;
  font-size: 0.83em;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
const FooterComponent = styled.footer`
  position: sticky;
  bottom: 0;
  z-index: 0;
  background-color: #323232;
  color: #fff;
  .wrapper-footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    align-items: baseline;
    height: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 1em 1em;
    justify-items: center;
    @media (max-width: 366px) {
      justify-items: start;
      grid-gap: 0.5em;
    }
  }
  .colum-container {
    max-width: 200px;
  }
  & .header-text-colum {
    font-weight: 400;
    margin: 1em 0em;
    font-size: 1rem;
  }
  & p {
    font-weight: 300;
    font-size: 0.83em;
    margin: 1em 0em;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;

const FooterMobile = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openTutorial, setOpenTutorial] = React.useState(false);
  const [openUserGuide, setOpenUserGuide] = React.useState(false);
  const [openFAQ, setOpenFAQ] = React.useState(false);
  const handleClickOpen = (type) => () => {
    if (type === "contact") {
      setOpen(true);
    }
    if (type === "tutorials") {
      setOpenTutorial(true);
    }
    if (type === "userGuide") {
      setOpenUserGuide(true);
    }
    if (type === "faq") {
      setOpenFAQ(true);
    }
  };

  const handleClose = (type) => () => {
    if (type === "contact") {
      setOpen(false);
    }
    if (type === "tutorials") {
      setOpenTutorial(false);
    }
    if (type === "userGuide") {
      setOpenUserGuide(false);
    }
    if (type === "faq") {
      setOpenFAQ(false);
    }
  };

  return (
    <FooterComponent>
      <div className="wrapper-footer">
        <div className="colum-container">
          <div className="header-text-colum">{t("footer.Legal")}</div>
          <div
            style={{
              margin: "0.5em 0em",
            }}
          >
            <CustomLink to="/terms/">
              {t("footer.Terms and Conditions")}
            </CustomLink>
          </div>
          <div
            style={{
              margin: "0.5em 0em",
            }}
          >
            <CustomLink to="/policy/">{t("footer.Privacy Policy")}</CustomLink>
          </div>
        </div>
        <div className="colum-container">
          <div className="header-text-colum">{t("footer.Help")}</div>
          <p onClick={handleClickOpen("tutorials")}>{t("Tutorial")}</p>
          <Dialog
            PaperProps={{
              style: { width: "100%" },
            }}
            maxWidth={"sm"}
            onClose={handleClose("tutorials")}
            aria-labelledby="customized-dialog-title"
            open={openTutorial}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.5em 1em",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: 700,
                }}
              >
                {t("footer.Tutorials")}
              </p>
              <IconButton onClick={handleClose("tutorials")}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <DialogContent
              dividers
              style={{
                padding: "1em 0.5em",
              }}
            >
              <AppTutorial />
            </DialogContent>
          </Dialog>
          <p onClick={handleClickOpen("userGuide")}>
            {" "}
            {t("footer.User Guide")}
          </p>
          <Dialog
            PaperProps={{
              style: { width: "100%" },
            }}
            maxWidth={"sm"}
            onClose={handleClose("userGuide")}
            aria-labelledby="customized-dialog-title"
            open={openUserGuide}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                padding: "0.5em 1em",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: 700,
                }}
              >
                {t("footer.User Guide")}
              </p>
              <IconButton onClick={handleClose("userGuide")}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <DialogContent
              dividers
              style={{
                padding: "1em 0.5em",
              }}
            >
              <AppUserGuide />
            </DialogContent>
          </Dialog>
          <p onClick={handleClickOpen("faq")}>{t("footer.FAQ")}</p>
          <Dialog
            PaperProps={{
              style: { width: "100%" },
            }}
            maxWidth={"sm"}
            onClose={handleClose("faq")}
            aria-labelledby="customized-dialog-title"
            open={openFAQ}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                padding: "0.5em 1em",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: 700,
                }}
              >
                {t("footer.FAQ")}
              </p>
              <IconButton onClick={handleClose("faq")}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <DialogContent
              dividers
              style={{
                padding: "1em 0.5em",
              }}
            >
              <ListFaqComponent />
            </DialogContent>
          </Dialog>
        </div>
        <div className="colum-container">
          <p onClick={handleClickOpen("contact")}>{t("Contact.Contact Us")}</p>
          <Dialog
            PaperProps={{
              style: { width: "100%" },
            }}
            maxWidth={"xs"}
            onClose={handleClose("contact")}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                padding: "0.5em 1em",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: 700,
                }}
              >
                {t("Contact.Contact Us")}
              </p>
              <IconButton onClick={handleClose("contact")}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <DialogContent
              dividers
              style={{
                padding: "1em 0em",
              }}
            >
              <ContactForm />
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </FooterComponent>
  );
};

export default FooterMobile;
